// Background
$background: #DFDFDF;
$bg-column: $background;
$base-color: $background;
$bgBody: $background;

// Text
$textColor: #424C55;
$headingWeight: 500;

// Heading + Links
$eye-color: #B50000;
$headingColor: $eye-color;
$link-color: #FF3333;

// Text Font-Family
$mainFont: 'Roboto', sans-serif;
$secondaryFont: $mainFont, monospace;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@200;300;400;600&family=Roboto:wght@100;300;400;700&display=swap');

/* SCSS HEX */
$gainsboro: #dfdfdfff;
$charcoal: #424c55ff;
$tart-orange: #ff3333ff;
$international-orange-engineering: #b50000ff;
$black-chocolate: #0e0f05ff;

// nav & footer
$navIcons: $eye-color;
$bgSecondary: $base-color;
$navMenu_shadow: 5px 0px 5px -10px rgba(66, 68, 90, 1);
$navBorder: rgba(255, 255, 255, 0.15);

*, *:before, *:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {

  h1, h2, h3, h4, h5, h6 {
    color: $eye-color;
    font-weight: $headingWeight;
    font-family: $secondaryFont;
  }

  .imgGallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;

    img {
      max-width: 300px;
      margin: 5px;
    }
  }

  .siteWrap {
    h2 {
      font-size: 2.5rem;
      margin-bottom: 2rem;
    }
  }

  .sectionContent {
    justify-content: center;
    margin: 0 auto;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  .App {
    overflow-x: hidden;
    background-color: $bgBody;
    min-height: 100vh;

    .siteTitle {
      color: $eye-color;
      font-size: 1.5rem;
    }
  }

  .ant-divider {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: $textColor !important;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    border-top: 1px solid rgb(255 255 255);
  }

  .row {
    background-color: $background;
  }

  p {
    font-family: $mainFont;
    color: $textColor;
    font-size: 16px;
    line-height: 2em;
    padding: 0 0 1em 0;
  }

  .wrapper {
    margin: auto auto;

    .container {
      margin-top: 5vh !important;
      margin-bottom: 50vh !important;
    }
  }

  .ant-table {
    background: none;
    color: white;
  }

  .ant-layout {
    background: none;
    color: $textColor;

    b {
      color: #eaeacd;
    }
  }
}

// hero
.header {
  // box
  width: 100%;
  min-height: calc(100vh - 65px);
  color: $textColor;
  position: relative;
  display: flex;
  align-items: center;

  // bg
  background-image: linear-gradient(to bottom, rgba(89, 131, 83, 0), rgb(223, 223, 223)),
  url('./../img/baner FB 01.png');
  background-size: cover;

  .row {
    background: none;
  }

  .hero {
    max-width: 1400px;
    margin: 0 auto;
  }

  .heroTitle {
    font-style: normal;
    font-weight: $headingWeight;
    color: $textColor;

    h2 {
      font-size: 3.7rem;
      line-height: 45px;
    }

    p {
      font-family: $mainFont;
      font-size: 1.5rem;
    }

    a {
      font-size: 1.25rem;
      color: $link-color;
      font-family: $mainFont;
      text-decoration: none;
    }

    h2, span, p {
      margin: 10px 0;
      padding: 15px 10px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

// menu
.topMenu {
  height: 62px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: $bgSecondary;
  padding: 20px;
  font-family: $mainFont !important;
  border-bottom: 1px solid $textColor;

  @media (max-width: 1199px) {
    .defaultMenu {
      display: none !important;
    }
  }

  .defaultMenu {
    display: flex;
    flex-direction: row;
  }

  a {
    color: $navIcons !important;
  }

  .navbar {
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
    z-index: 100;
  }

  @media (min-width: 1200px) {
    .navbar {
      display: none;
    }
  }

  .menu-bars {
    font-size: 2rem;
    background: none;

    .menu-bars_icon {
      padding-left: 20px;
    }

    svg {
      fill: $navIcons;
    }

    :hover {
      color: #f06873 !important;
    }
  }

  .nav-menu {
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 100;
    background: $bgSecondary;
    box-shadow: $navMenu_shadow;
  }

  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }

  .nav-text {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 8px 0 8px 16px;
    list-style: none;
    height: 60px;
    transition: 0.3s;

    span {
      margin-left: 16px;
    }

    a {
      text-decoration: none;
      font-size: 18px;
      width: 95%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 16px;
    }

    a:hover {
      background-color: $eye-color;
      color: $background !important;
      font-weight: $headingWeight;
    }
  }

  .nav-menu-items {
    width: 100%;
  }

  .navbar-toggle {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
}

// blog
.blog {
  font-family: sans-serif !important;

  .articleMenu {
    margin: 20px 0;
    width: 100%;
  }

  .article {
    margin: 0 auto;

    a {
      margin: 0 5px;
      color: $link-color;
    }

    h1, h2, h3, h4 {
      color: $eye-color;
    }

    h1 {
      font-size: 45px;
    }

    h4 {
      font-size: 25px;
    }
  }

  h1 {
    color: $eye-color;
    margin: 20px 0;
    font-size: 35px;
    font-weight: $headingWeight;
  }
}

// footer
.footer {
  background: $bgSecondary;
  color: $textColor;
  margin-top: 100px;

  a {
    color: $link-color;
  }

  h3 {
    text-align: left !important;
    color: $eye-color;
    font-weight: $headingWeight;
    margin: 20px 0;
  }

  .row {
    padding: 50px;
  }

  .col-lg-12 {
    h1 {
      font-size: 128px;
      color: $eye-color;
      opacity: 0.1;
      position: absolute;
    }
  }

  img {
    width: 100%;
    max-width: 225px;
    height: auto;
    text-align: center;
  }
}


// media queries
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 0 !important;
  }
}

@media (max-width: 991px) {
  .img-header {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .tableProduct {
    img {
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .tableDesc {
    left: 0;
  }

  .tableDesc_Object {
    right: 0;
    display: none;
  }
}