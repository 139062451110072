$sectionBG: #424C55;

.listed {
  width: 100%;
  font-family: sans-serif;
  margin: 25px 0;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px;
  }

  li {
    list-style: none !important;
    color: white;
    text-align: center;
    position: relative;
    width: 400px;
  }

  span {
    position: relative;
    width: 100%;
    color: black;
    text-align: center;
    background: yellow;
  }

  h2 {
    background: #101110;
  }

  p {
    background: #2d2c2c;
  }

  h2, p {
    margin: 0 !important;
    padding: 0;
    color: white;
  }

  h2, p, span {
    padding: 10px;
  }

  .listedPerson {
    margin: 50px 0;

    div {
      overflow: hidden;
    }

    h2 {
      text-transform: uppercase !important;
    }
  }
}

.imgGallery {
  background: $sectionBG;
  margin: 50px 0;
  padding: 50px 0;

  h2 {
    color: white;
  }
}

.walkingBox {
  .row {
    border: 5px dashed black;

    h3 {
      font-size: 25px;
      text-align: center;
      margin-top: 50px;
    }
  }
}

.sitewrapper {
  display: flex;
  justify-content: center;

  .col-lg-6 {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-sizing: content-box;
  }
}